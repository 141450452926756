import React, { useState, useEffect } from "react";
import { Container, Row, Col, CarouselItem, Button, CardDeck, Card, CardTitle, CardText, CardImg } from "reactstrap";

import Layout from "../components/layout";
import SEO from "../components/seo";

import banner from "../images/contact-02-crop-u410153.png";

import phone from "../images/drive_revenue_svg-13.svg";
import email from "../images/drive_revenue_svg-14.svg";
import { func } from "prop-types";
import RequestADemo from "../components/request-a-demo";
import Contact from "../components/contact";

const addressItems = [
    {
        title: "South Africa",
        address: [
            "1st Floor Sedgwick House",
            "24 Bloem Street, Cape Town, South Africa, 8001",
        ],
        phone: "+27 21 286 0735",
        email: "info@driverevenue.net",
        latitude: -33.925070,
        longitude: 18.415400,
        zoom: 13,
        containerId: "map-south-africa",
    },
    {
        title: "The Netherlands",
        address: [
            "Stationsplein 45, unit A4.004",
            "3013 AK Rotterdam",
        ],
        phone: "+31 64 147 4330",
        email: "info@driverevenue.net",
        latitude: 51.923340,
        longitude: 4.469160,
        zoom: 12,
        containerId: "map-the-netherlands",
    },
];

function initMap(latitude: number, longitude: number, mapZoom: number, containerId: string) {
    const markerTitle = "Marker Title",
        useSnazzy = true,
        mapType = "ROADMAP";
    // useCoordinates = parseBoolean("{param_useCoordinates}"),
    // address = encodeURIComponent("{param_address}"),
    // addressMapType = "{param_addressMapType}",
    // addressExpandPin = "{param_addressExpandPin}",
    // addressZoom = parseInt("{param_addressZoom}");

    const styles = [
        {
            "featureType": "administrative",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#444444"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#f2f2f2"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": -100
                },
                {
                    "lightness": 45
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#796eff"
                },
                {
                    "visibility": "on"
                }
            ]
        }
    ];

    var mapOptions = {
        center: new google.maps.LatLng(latitude, longitude),
        zoom: mapZoom,
        panControl: true,
        zoomControl: true,
        mapTypeControl: true,
        streetViewControl: true,
        mapTypeId: google.maps.MapTypeId[mapType],
        scrollwheel: true,
        styles: useSnazzy ? styles : []
    };

    const map = new google.maps.Map(document.getElementById(containerId), mapOptions);

    // Custom marker to the map
    const marker = new google.maps.Marker({
        position: new google.maps.LatLng(latitude, longitude),
        map: map,
        visible: true,
        title: markerTitle
    });
}

const googleMapsUrl = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCaO-5pR4a4IMwm_16nVRhlW6WZiMp13ck";

const ContactUs = () => {
    const addressCards = addressItems.map(x =>
        <Card key={x.title} body style={{ background: "#fff" }} className="shadow">
            <CardTitle tag="h1" className="text-secondary text-uppercase text-center pt-4 pb-3">
                {x.title}
            </CardTitle>
            <CardText className="text-center d-flex flex-column align-items-center pb-4 pt-4" tag="div">
                <h2 className="text-uppercase text-primary h4 font-weight-bold">
                    Physical Address
                </h2>
                {x.address.map(x => <>{x}<br /></>)}

                <h2 className="text-uppercase text-primary h4 font-weight-bold pt-5">
                    Contact Details
                </h2>
                <div className="d-flex pb-2">
                    <img src={phone} style={{ width: 22 }} className="mr-3" />
                    <a href={`tel:${x.phone}`}>
                        {x.phone}
                    </a>
                </div>
                <div className="d-flex">
                    <img src={email} style={{ width: 22 }} className="mr-3" />
                    <a href={`mailto:${x.email}`}>
                        {x.email}
                    </a>
                </div>
            </CardText>
            <div id={x.containerId} style={{ height: 251 }} />
        </Card>
    );

    useEffect(() => {
        if (!document.querySelectorAll(`[src="${googleMapsUrl}"]`).length) {
            document.body.appendChild(Object.assign(
                document.createElement("script"),
                {
                    type: "text/javascript",
                    src: googleMapsUrl,
                    onload: () => {
                        addressItems.forEach(x => initMap(x.latitude, x.longitude, x.zoom, x.containerId));
                    }
                }));
        }
    });

    const smoothScroll = (e: React.MouseEvent) => {
        e.preventDefault();

        const id = e.currentTarget.getAttribute("href").slice(1);
        const anchor = document.getElementById(id);
        const offsetTop = anchor.getBoundingClientRect().top + window.pageYOffset;

        window.scroll({
            top: offsetTop,
            behavior: "smooth",
        });
    }

    return (
        <Layout>
            <SEO title="Contact Us">
                <script src="" />
            </SEO>

            <Container fluid>
                <Row>
                    <Col className="top-carousel pl-0 pr-0">
                        <CarouselItem
                            className="d-flex align-items-center top-carousel"
                        >
                            <div className="carousel-caption d-none d-md-block position-relative text-left mb-4 pb-4" style={{ left: "10%" }}>
                                <h1 className="display-3">
                                    Contact us
                                </h1>
                                <p className="pt-4 pb-4 mt-4 mb-4 h5">
                                    Need to talk to an expert?
                                </p>
                                <Button outline color="light text-uppercase mb-4 rounded-pill pr-5 pl-5 pt-3 pb-3" size="lg" tag="a" href="#address" onClick={smoothScroll}>
                                    Let's Connect
                                </Button>
                            </div>

                            <img src={banner} style={{ height: "70vh" }} className="" />

                        </CarouselItem>
                    </Col>
                </Row>
            </Container>

            <Container className="pt-5 mt-5 mb-5 pb-5">
                <Row className="pt-5 pb-5">
                    <Col>
                        <h1 className="display-3 text-center text-primary mb-5">
                            We’d love to help your Practice reach new highs.
                        </h1>
                        <p className="lead pt-4 text-center">
                            Please use one of the buttons below to request a demo or send us a message.
                        </p>
                        <p className="text-center pt-4">
                            <RequestADemo>
                                {onClick =>
                                    <Button className="text-uppercase m-4 rounded-pill pl-4 pr-4" color="primary" size="lg" onClick={onClick}>
                                        Request a demo
                                    </Button>
                                }
                            </RequestADemo>
                            <Contact>
                                {onClick =>
                                    <Button className="text-uppercase m-4 rounded-pill pl-4 pr-4" color="secondary" size="lg" onClick={onClick}>
                                        Send us a message
                                    </Button>
                                }
                            </Contact>
                        </p>
                    </Col>
                </Row>
            </Container>

            <Container id="address" fluid style={{ background: "linear-gradient(to bottom,#3750C2 ,#151445 100%)" }}>
                <Row>
                    <Col>
                        <Container>
                            <Row className="justify-content-center pt-5 mt-5">
                                <Col md={9} className="mb-5 mt-5 pt-5">
                                    <h1 className="display-4 text-white text-center">
                                        Team Drive are here to help with any questions you have.
                                    </h1>
                                    <p className="text-center text-secondary lead pt-5">
                                        Simply get in touch with a member of the team in your country.
                                    </p>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <CardDeck>
                                        {addressCards}
                                    </CardDeck>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            <div style={{ marginTop: -200, height: 400, background: "#fff" }} />

        </Layout>
    );
};

export default ContactUs;
